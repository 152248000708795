<template>
	<div class="couponBox" :class="!isMobile ? 'pcbox' : ''">
		<div class="conBox1">
			<img :src="bg">
			
		</div>
		
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import useConfigStore from '@/store/modules/config'
	
	const { proxy } = getCurrentInstance();
	const lang = proxy.$i18n.locale
	const bg = require('@/assets/images/'+lang+'/pcsetup.png')
	
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	onMounted(() => {
		
	})
	
	
</script>

<style lang="less" scoped>
	.pcbox{
		.conBox1,.conBox2{
			max-width: 600px;margin: 0 auto;font-size: 0;
		}
	}
	
</style>